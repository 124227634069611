import SMERouter from 'sme-router';
import User, {localStorageUserDataKey} from 'lib@/user';
import artRuntime from 'art-template/lib/runtime';
// import tagList from 'lib@/tag_extends/tag';
import { urlEncode, log, getObjProperty, myRound, myParseInt, withDefault, isInFrame, afterFor } from 'lib@/util';

/**
 * 平台总入口
 */

const router = new SMERouter('main_page', 'html5');

App.router = router;

// 为 art-template 设置相关属性
artRuntime.log            = log;
artRuntime.getObjProperty = getObjProperty;
artRuntime.ceil           = Math.ceil;
artRuntime.myRound        = myRound;
artRuntime.withDefault    = withDefault;
artRuntime.parseInt       = parseInt;
artRuntime.myParseInt     = myParseInt;

// 为 art-template 设置tag对应的方法
// for(let k in tagList){
//     artRuntime[k] = tagList[k];
// }

$('body').on('click', 'a[route-url]', function (event){
    let elm = $(this);
    event.preventDefault();
    App.router.go(elm.attr('href'));
});

$('body').on('click', '*[route-goback]', function (event){
    event.preventDefault();
    App.router.back();
});

let isStorageEventHandled = false;
/**
 * 监听localstorage token更改事件，强制用户刷新或关闭页面
 */
const storageEventHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.key === localStorageUserDataKey) {
        if (isStorageEventHandled) {
            return;
        }
        const oldUser = e.oldValue;
        const newUser = e.newValue;
        if ((oldUser && newUser && oldUser.token !== newUser.token) || (oldUser && !newUser)) {
            // token变更 || 用户信息被清空。 提示用户刷新页面或关闭当前页面
            isStorageEventHandled = true;
            window.top.removeEventListener('storage', storageEventHandler);
            layui.layer.confirm(
                '检测到您的账号在另一个标签页已登录或登出。为了确保您的账号安全，请选择刷新页面。如果不需要此页面，请手动关闭。',
                {icon: 3, title: '提示', closeBtn: 0, btn: ['刷新页面'], area: 'auto', maxWidth: 360, move: false},
                function (index) {
                    layui.layer.close(index);
                    window.top.location.replace('/'); // 跳转到首页
                }
            );
        }
    }
}

// 如果是在iframe内，则不监听storage事件
if (!isInFrame()) {
    window.top.addEventListener('storage', storageEventHandler)
}


/**
 * 最后一个显示的页面对象
 */
let lastPage = null;

function showPage(page){
    afterFor(
        () => {
            return page.isShowDefaultPage;
        },
        150
    ).then(() => {
        if(lastPage){
            lastPage.leave();
        }

        if(!page){
            return;
        }

        window.currentPage = page;

        page.enter();

        lastPage = page;

        // 当前在frame内的话，则需修改浏览器地址栏的连接
        if(isInFrame() && window.history){
            window.top.history.replaceState({}, '', App.req.url);
        }
    });

}

router.route(
    '/login',
    (req, res, next) => {

        if(isInFrame()){
            window.top.location = req.url;
        }
        else{
            App.req  = req;
            App.res  = res;
            App.next = next;

            App.getPage('login').then((page) => {
                showPage(page);
            });
        }
    }
);

router.route(
    '/404',
    (req, res, next) => {

        App.req  = req;
        App.res  = res;
        App.next = next;

        App.getPage('error404').then((page) => {
            showPage(page);
        });
    }
);

// 数据的报告单页
router.route(
    '/ext/rct/ground/report/page-rct',
    (req, res, next) => {

        App.req  = req;
        App.res  = res;
        App.next = next;

        App.getPage('ext/rct/ground/report/page-rct').then((page) => {
            showPage(page);
        });
    }
);


// 下载数据的单页
router.route(
    '/ext/rct/ground/project-manage/export-join-group-detail',
    (req, res, next) => {

        App.req  = req;
        App.res  = res;
        App.next = next;

        App.getPage('ext/rct/ground/project-manage/export-join-group-detail').then((page) => {
            showPage(page);
        });
    }
);

//ECRF 病例报告单页
router.route(
    '/ext/ecrf/ground/assessment_report/visit',
    (req, res, next) => {

        App.req  = req;
        App.res  = res;
        App.next = next;

        App.getPage('ext/ecrf/ground/assessment_report/visit').then((page) => {
            showPage(page);
        });
    }
);

router.route(
    '*',
    (req, res, next) => {
        let url          = (req.url.split('?'))[0];
        let defaultRoute = User.getDefaultRoute();

        if(/^(\/|\/index)(\?.*){0,1}$/.test(url)){
            if(defaultRoute != ''){
                router.redirect(defaultRoute);
            }
            else{
                router.go('/login');

            }
            return;
        }
        // 没有默认路由表示未登录
        else if(!defaultRoute){
            router.go('/login');
            return;
        }

        App.req  = req;
        App.res  = res;
        App.next = next;

        let tplData = {
            pageUrl: req.url
        };

        App.outputLayout(tplData, lastPage).then(() => {
            return App.getPage(url);
        })
        .then((page) => {
            showPage(page);
        });
    }
);
